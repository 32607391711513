import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';
import TeslaAtPeer from '../images/tesla_at_pier.jpg';
import TeslaX from '../images/tesla_x.jpg';

const AboutPage = () => (
  <Layout>
    <SEO title="About E1 Transit" />
    <main>
      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="vision"
      >
        <section
          id="intro"
          style={{ height: '600px', backgroundImage: `url(${HeroImage})` }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>The Vision</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 container main-text">
          <p>
            <strong>Mission</strong>
          </p>
          <p className="padding-bottom-25">
            E1 Transit will adopt leading technology in transportation to
            establish the world’s most sustainable transportation network that
            spans every city. Employing emissions-free vehicles that adapt and
            evolve, as technology changes will connect people, places and
            businesses – helping to create a more sustainable future for
            everyone.
          </p>

          <p>
            <strong>Vision</strong>
          </p>
          <p className="padding-bottom-25">
            Connecting people across Canada through sustainable and innovative
            transportation solutions
          </p>

          <p>
            <strong>
              Creativity - Sustainability - Innovation – Passion – Collaboration
              – Family
            </strong>
          </p>
        </div>
      </div>

      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="sustainability"
      >
        <section
          id="intro"
          style={{
            height: '600px',
            backgroundImage: `url(${TeslaAtPeer})`,
            backgroundPositionY: 'center',
          }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>E1 Transit Sustainability</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 container main-text">
          <p className="padding-bottom-25">
            At E1 transit our fleet is completely zero-emissions, using solely
            100% electric vehicles. We believe in protecting the environment and
            we feel that using all-electric vehicles is the only way to roll. At
            E1 we support a more sustainable future by building a more robust
            electric vehicle charging infrastructure, promoting renewable energy
            and reducing harmful waste. We love to support initiatives that
            drive sustainability and improve our customer experience. In fact,
            we want to hear from our E1 friends and family and work together to
            build a sustainable future.
          </p>

          <p className="padding-bottom-25">
            On a typical 200km trip our E1 Transit electric vehicle produces up
            to 188g of CO2 per passenger. That same trip in a gasoline powered
            bus or van produces up to 5,360g of CO2 per passenger - E1 Transit
            reduces emissions by 96.5% over conventional inter-city transit
            options. E1 Transit takes very positive steps in reducing the
            harmful emissions produced each year across Canada providing a clear
            benefit to the health of Canadians and our planet.
          </p>

          <p className="padding-bottom-25">
            “At E1 transit, our primary objective is to accelerate electric
            transportation without compromising our planet. We have a relentless
            pursuit for social, economic, and environmental sustainability.” -
            Christopher Misch, CEO E1 transit.
          </p>
        </div>
      </div>

      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="the-cars"
      >
        <section
          id="intro"
          style={{ height: '600px', backgroundImage: `url(${TeslaX})` }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>Electric Vehicles</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 container main-text">
          <p className="padding-bottom-25">
            E1 Transit uses the Tesla Model S to transport our customers.
          </p>

          <p className="padding-bottom-25">
            Our electric blue fleet commands attention and makes a statement
            about E1 Transit’s unrelenting passion to create a more sustainable
            future. E1 Transit uses the most advanced electric vehicles in the
            World to transport our customers, including the award winning Tesla
            Model X and Model S. The vehicles are the safest and most advanced
            vehicles available offering guests an experience that makes the
            journey as important as the destination. The Tesla Model X recently
            received the highest safety rating of any sport utility vehicle ever
            produced. See: 
            <a
              href="https://techcrunch.com/2017/06/13/teslas-model-x-earns-all-around-5-star- safety-rating-from-nhtsa/"
              target="_blank"
              rel="noreferrer"
            >
              https://techcrunch.com/2017/06/13/teslas-model-x-earns-all-around-5-star-
              safety-rating-from-nhtsa/
            </a>
          </p>

          <p className="padding-bottom-25">
            Our vehicles offer the latest amenities so you can sit back in
            luxury and enjoy the ride. We offer free Wi-Fi, pillows, organic
            snacks and pure spring water. In addition, our drivers and concierge
            will create a memorable experience. Book a ride and let E1 transit
            take the worry out of your trip.
          </p>
        </div>
      </div>
    </main>
  </Layout>
);

export default AboutPage;
